import {useState} from 'react';
import { MDBAlert } from 'mdb-react-ui-kit';

const Alert = ({color, classes, onClose, children }) => {
    const [visibility, setVisibility] = useState(true);    
    let styles = ((children && typeof children =="object") && children.length>5)?{ "maxHeight": "100px", "overflowY": 'scroll' }:'';    
    return (
        <div className='alert-container'>
            <MDBAlert open={visibility} className='w-100' color={color} onClose={onClose} style={styles}>
                {children}
                {onClose != undefined && <button type='button' aria-label='Close' className="btn-close float-end" onClick={() => setVisibility(false)} />}
            </MDBAlert>
        </div>
      
    );
};
export default Alert;
