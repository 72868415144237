import React, { Component } from 'react';
import Routes from 'Routes';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'styles/style.css';
import 'styles/reset.css';
import 'styles/common.css';
import {disableReactDevTools} from 'disableDevTools';
import Loader from 'components/Common/components/Loader/Loader';
class App extends Component {
  constructor() {
    super();
    this.state = {loader: false,}
  }
  async handleServiceWorkerUpdate(){
    try {
      // Wait until the Service Worker is installed and controlling
      const registration = await navigator.serviceWorker.ready;

      // Check for waiting Service Workers (indicates a new version is available)
      if (registration.waiting) {
        this.setState({ loader: false });
      }

      // Monitor the Service Worker state
      navigator.serviceWorker.addEventListener('controllerchange', () => {
         this.setState({ loader: false });
      });

      // Listen for the updatefound event
      registration.onupdatefound = () => {
        this.setState({ loader: true });
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
            this.setState({ loader: false });
            }
          };
        }
      };
    } catch (error) {
      console.error('Service Worker registration failed:', error);
      this.setState({ loader: false });
    }
};
componentDidMount(){
  this.handleServiceWorkerUpdate();
}
render() {
  if (process.env.REACT_APP_ENV === "production") disableReactDevTools();
  return (
    <div>        
        <Routes />  
        <Loader show={this.state.loader} />              
    </div>
  );
} 

}

export default App;
