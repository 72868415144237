import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'styles/logo.png';
import Loader from 'components/Common/components/Loader/Loader'
import Authenticate from 'components/Account/components/Authenticate'
import NotFound from "../NotFound/NotFound";
import { withNavigation } from 'Common/utils/WithNavigation';
/**
 * Payment receipt page
 */
class PaymentReceipt extends Component {
    constructor(props) {
        /** Props will make all component properties available in the constructor */
        super(props);
        this.auth = new Authenticate();
        this.state = {
            response: {},
            errorMessage: '',
            errorMessageClass: '',
            loader: false,
            name: 'Printer'
        }
        this.params = localStorage.getItem('params');
        localStorage.setItem('params', null);
        this.params = JSON.parse(this.params);
    }
    /** For Logout */
    logout = () => {
        var sPropertyTransitId = this.auth.userInfo.sPropertyTransitId;
        localStorage.clear();
        localStorage.setItem('db',sPropertyTransitId);
        localStorage.setItem('customer',sPropertyTransitId);
        window.location.href = '/payment-login/' + sPropertyTransitId;
    }
    /** End Logout Function */


    printReceipt = () => {
        window.print();
    }

    /**    
     * Function will identify enter key press.  if enter key is pressed click event for submit button will trigger.
     * @param {*} e for event
     */
    /** Disable Browser back button Function */
    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    }
    showFile(file){
        window.open(
            file,
            '_blank'
          );
    }
    /** End Disable Browser back button Function */
    render() {
        if (this.params === null) {
            return <NotFound />;
        }
        if (this.state.status_code) {
            return this.props.navigateTo("/logout");
        }
        if(this.params.applicationURL !== ''){
            setTimeout(() => {
                this.showFile(this.params.applicationURL)
            }, 5000);
        }
        return (
            <div className="payment-portal-wrapper print-container">
                <Authenticate token='' />
                <div className="payment-portal-header">
                    <div className="payment-portal-container-main">
                        <div className="payment-portal-logo"><img src={logo} alt="Logo" /></div>
                        <div className="header-account-links">
                            <ul>
                                <li><span className="logout-text">{this.auth.userInfo.customer_name}, </span>
                                    <Link to="#" onClick={this.logout} title="Log Out"><span className="logout-text">Logout</span> <i className="fa fa-power-off fa-lg" title="Log Out"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="userinfo-heading">
                        <h1 className="thanks-heading">Thank you for submitting your payment to {this.params.userInfo.sCompanyName}</h1>
                        <Link to="/payment" title="Make a new payment" className="make-new-payment"><i className="fa fa-caret-right"></i> Make a new payment </Link>
                    </div>
                    <div className="clear"></div>
                    <div className="payment-portal-container">
                        <h2>Payment Information</h2>
                        <div className="review-payment-container">
                            <label>Payment Type:</label>
                            <div className="review-payment-details">{this.params.payment_method === 'creditCard' ? 'Credit Card' : 'Bank Draft'}</div>
                        </div>
                        <div className="review-payment-container">
                            <label>Payment Confirmation:</label>
                            <div className="review-payment-details">{this.params.invoice_number}</div>
                        </div>
                        <div className="review-payment-container">
                            <label>Payment Amount:</label>
                            <div className="review-payment-details">${this.params.net_amount}</div>
                        </div>
                        <div className="review-payment-container">
                            <label>Your Account / Number:</label>
                            <div className="review-payment-details">{this.params.userInfo.sAccountName}</div>
                        </div>
                        <div className="review-payment-container">
                            <label>Lease:</label>
                            <div className="review-payment-details">{this.params.userInfo.pbaccount}</div>
                        </div>
                        <div className="review-payment-disclaimer">
                            <p>This payment information will be reviewed and processed within two business days. All payments are subject to acceptance under the terms of your contract with {this.params.userInfo.sCompanyName}. You will be notified by {this.params.userInfo.sCompanyName} in the event this payment could not be processed due to insufficient funds, incorrect account information, declined account, or any other reason.</p>
                            <p className="review-payment-disclaimer-important">Please print a copy of this page for your records.</p>
                        </div>
                        <button className="button print-reciept-button" onClick={() => this.printReceipt()}>Print Receipt</button>
                        <div className="payment-portal-disclaimer">
                            <p>This information was obtained from sources believed to be reliable, but no representations or warranties, expressed or implied, as to the accuracy of the information are made.  You are responsible for verifying the information and bear  all risk for inaccuracies.  Pricing and other details are subject to change.  Contact the leasing office for the latest information.</p>
                            <Link target='_blank' to="/terms-and-conditions" className="simple-link">View Terms &amp; Conditions</Link>
                            <div style={{display: "block", pageBreakBefore: "always" }}>
                                <img src={logo} alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <Loader show={this.state.loader} />
            </div>
        );
    }
}

export default withNavigation(PaymentReceipt);
