import CryptoJS from 'crypto-js';

// Base64-decoded key for AES-256 encryption
const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_API_ENCRYPTION_KEY);

export const encryptData = (data) => {
    try {
        let iv = CryptoJS.lib.WordArray.random(16);
        let options = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        };
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, options);
        encrypted = encrypted.toString();
        iv = CryptoJS.enc.Base64.stringify(iv);
        let result = {
            iv: iv,
            value: encrypted,
            mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
        }
        result = JSON.stringify(result);
        result = CryptoJS.enc.Utf8.parse(result);
        return CryptoJS.enc.Base64.stringify(result);
    } catch (error) {
        console.error("Encryption error:", error);
        throw new Error("Error encrypting the data!");
    }
};

export const decryptData = (encryptedString) => {
    try {
        const encryptedObject = JSON.parse(atob(encryptedString));

        const iv = CryptoJS.enc.Base64.parse(encryptedObject.iv);
        const decrypted = CryptoJS.AES.decrypt(encryptedObject.value, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        const decryptedObject = JSON.parse(decryptedText);

        return decryptedObject;
    } catch (error) {
        console.error("Decryption error:", error);
        throw new Error("Error decrypting the data!");
    }
};
